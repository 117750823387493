<template>
  <va-card title="Admin Setting">
    <va-button color="purple" icon="entypo entypo-plus" @click="initiateImport()">
      Import Script
    </va-button>
    <a href="#" style="margin-left: 40px; margin-top: 8px;" @click="downloadFormat()">Download Script</a>
    <va-modal
      class="flex xs12"
      v-model="importModal"
      title="Import File"
      size='small'
      okText=""
      cancelText=""
      @cancel="cancelImport()"
      noEscDismiss>
      <va-button small color="danger" icon="entypo entypo-cancel" class="model_cancel" @click="cancelImport()"></va-button><br>
      <div style="text-align: center;">
        <img class="icon" src="@/assets/upload-subscription.png" style="width: 200px; height: 200px;" />
      </div>
      <div style="text-align: center;">
        <div v-if="bulkData" style="text-align: right;">
          <div class="row">
            <va-file-upload
              v-if="bulkData"
              type="single"
              v-model="importData"
            />
          </div>
          <va-button type="submit" class="my-0" @click.prevent="saveImport()">Save</va-button>
          <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="cancelImport()">Cancel</va-button>
        </div>
      </div>
    </va-modal>
  </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en'

Vue.use(vueResource)
export default {
  name: 'yt',
  data () {
    return {
      importModal: false,
      make_import: false,
      bulkData: false,
      importData: [],
      single: [],
    }
  },
  methods: {
    initiateImport () {
      this.importModal = true
      this.makeImport()
    },
    cancelImport () {
      this.importModal = false
      this.make_import = false
      this.bulkData = false
      this.bulkCreate = false
      this.mode = ''
      this.msgFlag = false
      this.importData = []
    },
    makeImport () {
      this.bulkData = true
      this.make_import = true
    },
    downloadFormat () {
      this.$http.get(config.menu.host + '/admin_setting').then(response => {
        var element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response.body))
        element.setAttribute('download', 'AdminScript.js')
        element.style.display = 'none'
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      }, err => {
        Vue.notify({ text: err.body, type: 'error' })
      })
    },
    saveImport () {
      var vm = this
      if (this.importData[0] && this.importData[0].type.includes('javascript')) {
        var fileContent = this.importData[0]
        this.selectedFile = fileContent
        if (typeof (FileReader) !== 'undefined') {
          var reader = new FileReader()
          reader.onload = function (e) {
            var payload = {
              file: e.target.result,
            }
            const loader = Vue.$loading.show({ width: 40, height: 40 })
            vm.$http.post(config.menu.host + '/admin_setting', payload).then(response => {
              loader.hide()
              vm.cancelImport()
              if (response && response.body) {
                Vue.notify({ text: response.body, type: 'success' })
              }
            }, error => {
              loader.hide()
              if (error && error.body) {
                Vue.notify({ text: error.body, type: 'error' })
              }
            })
          }
          reader.readAsText(this.selectedFile)
        }
      } else {
        return Vue.notify({ text: 'Please upload javascript file.', type: 'error' })
      }
    },
  },
}
</script>

<style scoped>

.model_cancel {
  float: right;
  margin-top: -8%;
  margin-right: -3%;
}

a:hover {
  text-decoration: underline;
}
</style>
